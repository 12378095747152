(function () {
  'use strict';

  /* @ngdoc object
   * @name neo.authentication
   * @description
   *
   */
  angular
  .module('neo.authentication', [
    'neo.authentication.login',
    'neo.authentication.registration',
    'ngMaterial'
  ]);
}());
